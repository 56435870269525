import React, { useState } from 'react'

import Modal from '../components/Modal'
import Box from '../components/Box'
import Button from '../components/Button'

const withNotice = content => SubComp => props => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      <SubComp {...props} />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        p="2em"
        customStyles={{
          top: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '50em',
        }}
      >
        <Box fontSize="1.375em" textAlign="justify">
          {content}
        </Box>
        <Box mt="2em" textAlign="center">
          <Button onClick={() => setIsOpen(false)}>確定</Button>
        </Box>
      </Modal>
    </>
  )
}

export default withNotice
