import React from 'react';

import ContentLayout from 'components/ContentLayout'

import Page from 'containers/ProgressPage'


export default () => (
  <ContentLayout title="調解案件進度查詢">
    <Page />
  </ContentLayout>
)
